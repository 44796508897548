const AppColors = {
  background: {
    default: '#000',
    paper: '#101518'
  },
  light: '#20C0D7',
  main: '#0054a6',
  dark: '#1e3d71',
  contrastText: '#fff',
  error: '#ff0000',
}

export const AppDarkTheme = {
  palette: {
    type: 'dark',
    background: {
      paper: AppColors.background.paper,
      default: AppColors.background.default,
    },
    text: {
      primary: "#ccc",
      contrast: "#fff",
    },
    border: {
      default: "RGBA(80,80,80,0.4)",
      primary: "RGBA(80,80,80,0.8)",
    },
    primary: {
      light: AppColors.light,
      main: AppColors.main,
      dark: AppColors.dark,
      contrastText: AppColors.contrastText,
    },
  }
};

