import React from 'react';
import Layout from './routing/Layout';
import './App.css';
import { RootState } from './contexts';



const App = () => {
  return (
    <RootState>
      <Layout />
    </RootState>
  );
}

export default App;
