import React from 'react';
import { useTheme, Box } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Hidden from '@mui/material/Hidden';
import { Menu } from '@mui/icons-material'
import '../../App.css';
import MenuStyles from './MenuDrawerStyles';
import AppBarUserItems from './AppBarUserItems';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Link } from 'react-router-dom';

import { LeftMenuItems } from './LeftMenuItems';

export default function MenuDrawer() {

  const theme = useTheme();
  const styles = MenuStyles;
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  const appBar = (
    <AppBar position="fixed">
      <Toolbar id="myToolb" sx={{
        display: "flex",
        justifyContent: 'space-between',
        width: '100%',
      }}>
        <Box sx={{ display: 'flex' }}>
          <IconButton color="inherit"
            aria-label="open drawer" onClick={handleDrawerOpen} edge="start"
            sx={{ ...styles.menuButton, '& .open': { display: 'none' } }}>
            <Menu />
          </IconButton>
          <Link className="logo" to={"/"}>&nbsp;</Link>
        </Box>
        <Hidden mdDown>
          <Box sx={{ width: '300px' }}>
            <Typography noWrap variant="h6" sx={{ textAlign: 'left' }}>TOOLS</Typography>
          </Box>
        </Hidden>
        <AppBarUserItems edge="end" />
      </Toolbar>
    </AppBar>
  );



  const drawer = (
    <Drawer variant="persistent" anchor="left"
      open={open}>
      <Box sx={{  height: theme.spacing(8), cursor: 'pointer' }} onClick={handleDrawerClose} title="Close menu">
        <Hidden lgUp>
          <Typography variant="h6" sx={{ marginRight: theme.spacing(2), textAlign: 'center' }}>TOOLS</Typography>
        </Hidden>
      </Box>
      <Divider sx={{ background: theme.palette.text.primary, opacity: .4 }} />
      <LeftMenuItems handleDrawerClose={handleDrawerClose} />
    </Drawer>
  );

  return (
    <ClickAwayListener onClickAway={handleDrawerClose}>
      <Box sx={{ ...styles.root }}>
        <CssBaseline />
        {appBar}
        {drawer}
      </Box>
    </ClickAwayListener>
  );
}
