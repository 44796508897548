import React, { useContext, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useTheme, Container } from '@mui/material';
import "../App.css";
import { RootContext } from '../contexts';
import Home from '../components/Home';
import ApduParser from '../components/tacho/apduParser/ApduParser';
import MenuDrawer from '../components/menu/MenuDrawer';
import SignIn from '../components/login/SignIn';
import { TachoGen2FileParser } from '../components/tacho/file/parser/TachoGen2FileParser';
import { FirmwareInfoParser } from '../components/tavl/firmware/info/FirmwareInfoParser';
import { DeviceRecordFileParser } from '../components/tavl/records/DeviceRecordFileParser';
import { AvlParser } from '../components/tavl/avlParser/AvlParser';
import { RawDataParser } from '../components/tavl/rawdata/RawDataParser';
import { DsSqlGenerator } from '../components/tavl/dsSlGenerator/DsSqlGenerator';
import { DefaultGet } from '../services/DefaultGet';
import { Storage, StorageKeys } from '../config';




export default function Layout() {
    const { state, changeClientIsLocal } = useContext(RootContext);
    const theme = useTheme();

    useEffect(() => {
        DefaultGet.fetch("/api/info").then(response => {
            Storage.setItem(StorageKeys.clientIp, response.clientIp);
            Storage.setItem(StorageKeys.clientIsLocal, response.clientIsLocal);
            if (state.clientIsLocal !== response.clientIsLocal) {
                changeClientIsLocal(response.clientIsLocal);
            }
        }).catch(ex => {
            if (state.clientIsLocal) {
                changeClientIsLocal(false);
            }
        });
    });

    return (
        <Container sx={{
            marginTop: theme.spacing(8),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& .MuiInputLabel-root.Mui-focused': {
                color: `${theme.palette.text.primary} !important`
            }
        }}>
            <MenuDrawer />
            <Routes maxWidth={false}>
                <Route key="home" path={"/home"} element={<Home />} />
                <Route key="signIn" path={`/signIn`} element={<SignIn />} />
                <Route key="apduParser" path={`/tacho/apduParser`} element={<ApduParser />} />
                <Route key="tacho-gen2" path={`/tacho/gen2`} element={<TachoGen2FileParser />} />
                <Route key="tavl-firmware-info" path={`/tavl/firmware/info`} element={<FirmwareInfoParser />} />
                <Route key="tavl-records-parser" path={`/tavl/records/parser`} element={<DeviceRecordFileParser />} />
                <Route key="tavl-packet-parser" path={`/tavl/packet/parser`} element={<AvlParser />} />
                <Route key="tavl-rawdata-parser" path={`/tavl/rawdata/parser`} element={<RawDataParser />} />
                <Route key="tavl-dssqlgenerator" path={`/tavl/dssqlgenerator`} element={<DsSqlGenerator />} />
                <Route key="*" path="*" element={<Home />} />
            </Routes>
        </Container>
    );
}