import { FetchHelper } from "./FetchHelper";

async function upload(pathAndQuery, file) {

    const formData = new FormData()
    formData.append('name', file.name);
    formData.append('file_attachment', file);

    const requestOptions = {
        method: "POST",
        body: formData
    };

    const url = pathAndQuery;

    return await fetch(url, requestOptions)
        .then(response => {
            console.log(response);

            if (response.status !== 200) {
                return Promise.reject(response);
            }

            return FetchHelper.useResponseParser(response);
        })
        .then((result) => {
            console.log(result);
            return Promise.resolve(result);
        })
        .catch(ex => {            
            return FetchHelper.tryParseBody(ex).then((exData) => Promise.reject(exData));
        });
}

export const DefaultFileUpload = {
    upload: upload
};
