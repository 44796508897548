import React, { useState, useRef } from 'react';
import { Container, Typography, Button, TextField, Box } from '@mui/material';
import { Save } from '@mui/icons-material';
import { DefaultFileUpload } from '../../../services/DefaultFileUpload';
import { Helpers } from '../../../config';
import { FileUploadButton, InfoPopup, InfoTypes } from '../../common';
import LoadProgress from '../../../routing/LoadProgress';
import { DsSqlContent } from './DsSqlContent';
import useAppStyles from '../../common/useAppStyles';




export const DsSqlGenerator = (props) => {

    const { inputStyle, container } = useAppStyles();

    const styles = {
        controls: {
            display: 'flex',
            justifyContent: 'space-evenly',
        },
        caption: {
            textTransform: 'uppercase',
            textAlign: 'center',
        },
        fileBrowseButton: {
            paddingTop: '12px',
        },
        fileInfo: {
            display: 'flex',
            justifyContent: 'space-between',
            paddingBottom: '10px',
        },
    };
    const inputEl = useRef(null);
    const [state, setState] = useState({ error: false, file: '', content: '' });
    const [fetching, setFetching] = useState(false);
    const [moduleName, setModuleName] = useState('');

    const onFileSelected = (event) => {

        const selectedFile = inputEl.current.files[0];

        const url = `/api/datasourcesqlgenerator?moduleName=${moduleName}`;

        const promise = DefaultFileUpload.upload(url, selectedFile);
        setFetching(true);
        promise.then((result) => {
            setState({
                error: (result.errors && result.errors.length) || (result?.parsedRowCount ?? 0) === 0,
                file: selectedFile.name,
                content: result
            });
        }).catch((ex) => {
            var message = Helpers.stringifyError(ex);
            setState({ error: true, file: selectedFile.name, content: { errors: [message], sql: '', parsedRowCount: 0, excelRowCount: '?' } });
        }).finally(() => {
            setFetching(false);
            if (inputEl.current && inputEl.current.value)
                inputEl.current.value = '';
        });
    };


    const saveToFile = () => {
        const mimeType = 'text/plain';
        const element = document.createElement("a");
        const data = state.content.sql;
        const file = new Blob([data], { type: `${mimeType};charset=utf-8`, endings: 'native' });
        element.href = URL.createObjectURL(file);
        element.download = `${state.file}-${moduleName || 'AllModules'}.sql`;
        document.body.appendChild(element);
        element.click();
    };

    return (
        <>
            <LoadProgress show={fetching}></LoadProgress>
            <Container maxWidth={false} sx={{ ...container }}>
                <Typography variant="h5" sx={{ ...styles.caption }}>
                    DATA SOURCE (AVL ID) SQL GENERATOR/CHECKER <InfoPopup info={InfoTypes.dsSqlGeneratorInfo} />
                </Typography>
                <br />
                <Box sx={{ ...styles.controls }}>
                    <TextField
                        id="filter"
                        label="Module type"
                        title="Module type name (optional filter for request only)"
                        type="text"
                        InputLabelProps={{ shrink: true, }}
                        value={moduleName}
                        onChange={e => setModuleName(e.target.value)}
                        variant="outlined"
                        sx={{ ...inputStyle }}
                    />
                    <Box sx={{ ...styles.fileBrowseButton }}>
                        <FileUploadButton onFileSelected={onFileSelected} inputRef={inputEl} accept=".xls,.xlsx" />
                    </Box>
                </Box>
                <br />
                {!!state.file ?
                    <Box sx={{ ...styles.fileInfo }}>
                        <Typography variant="subtitle1">File: {state.file}</Typography>
                        <Button variant="outlined" color={state.error ? "secondary" : "primary"} component="span" title="Save text to file" onClick={saveToFile}>
                            <Save />
                            <span>Save SQL to file</span>
                        </Button>
                    </Box> : null}
                <DsSqlContent data={state.content} moduleName={moduleName} />
            </Container>
        </>
    );
}