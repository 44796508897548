const zeroDate = new Date(0);
export const Helpers = {
    isNoneOrDefault: function (value) {
        let result = false;
        switch (typeof (value)) {
            case 'boolean':
                result = !value;
                break;
            case 'string':
                result = value === '' || value === 'undefined' || value === 'null';
                break;
            case 'number':
                result = value === 0 || isNaN(value);
                break;
            default://object
                if (value instanceof Array) {
                    result = value.length === 0;
                    break;
                }
                if (value instanceof Date) {
                    result = value === zeroDate;
                    break;
                }

                result = value === null || value === undefined ||
                    (value && Object.keys(value).length === 0 && value.constructor === Object);
                break;
        }

        if (result) {
            //console.log(`Helpers.isNoneOrDefault -> true for [${value}]`);
        }

        return result;
    },

    clearFlatObjectValues: function (entity, defaultValue) {
        if (this.isNoneOrDefault(entity)) {
            console.log(`Helpers.clearFlatObjectValues(defaultValue:[${defaultValue}]) -> ERROR: object is empty!`)
            return;
        }
        Object.keys(entity).forEach(key => {
            if (typeof (entity[key]) === 'function') {
                return;
            }
            if (entity[key] instanceof Array) {
                entity[key] = [];
            } else {
                entity[key] = defaultValue || '';
            }
        });
        console.log(`Helpers.clearFlatObjectValues(defaultValue:[${defaultValue}]) -> ${JSON.stringify(entity)}`)
        return entity;
    },

    stringifyError: function (ex, prefix) {
        if (this.isNoneOrDefault(ex)) {
            console.log(`Helpers.stringifyError() -> ERROR: exception is empty!`)
            return 'Empty error';
        }

        let message = '';

        try {
            const status = ex.status;
            message += !!status ? `status: ${status}` : '';
        } catch{ }

        try {
            const title = ex.title;
            message += !!title ? `; title: ${title}` : '';
        } catch{ }

        try {
            const msg = ex.message;
            message += !!msg ? `; message: ${msg}` : '';
        } catch{ }

        try {
            const text = ex.statusText;
            message += !!text ? `; text: ${text}` : '';
        } catch{ }


        if (message === '') {
            message = JSON.stringify(ex) || `${ex}`;
            switch (message) {
                case "TypeError: Failed to fetch":
                    message += '. API server unreachable.';
                    break;
                case '':
                case '{}':
                    message = 'unknown exception';
                    break;
                default:
                    break;
            }
        }

        message = `${prefix || 'ERROR:'} ${message}`;
        console.log(message);
        return message;
    },

    formatXml: function (xml) {
        return xml.replace(/(&lt;)/g, "<")
            .replace(/(&gt;)/g, ">")
            .replace(/(&amp;)/g, "&")
            .replace(/(&quot;)/g, '"');
    },
};
