import React from 'react';
import { useTheme, Box } from '@mui/material';



const delimiter = '-----------------------';
export const DsSqlContent = (props) => {

    const theme = useTheme();
    const styles = {
        error: {
            color: theme.palette.error.main,
            fontSize: '1.2em',
        },
        success: {
            color: theme.palette.success.main,
            fontSize: '1.2em',
        },
    };
    const errors = props.data?.errors?.map((x, i) => <div key={"error_" + i}>{x}</div>) || <div>{delimiter}</div>;
    const hasErrors = (props.data?.errors?.length ?? 0) > 0 || (props.data?.parsedRowCount ?? 0) === 0;

    return (
        (props.data?.errors?.length || props.data?.sql?.length) ?
            <div>
                <hr />
                <h5>MODULE NAME: {props.moduleName ?? '*'}</h5>
                <h5>EXCEL FILE ROW COUNT: {props.data?.excelRowCount ?? 0}</h5>
                <h5>PARSED AVL ID * MODULE COUNT: {props.data?.parsedRowCount ?? 0}</h5>
                <h5 className={hasErrors ? styles.error : styles.success}>GENERATED SQL LENGTH: {props.data?.sql?.length ?? 0}</h5>
                <h5 className={hasErrors ? styles.error : styles.success}>SKIPPED ROW HAVING ERROR COUNT: {props.data?.errors?.length ?? 0}</h5>
                {hasErrors ?
                    <>
                        <h5>ERRORS:</h5>
                        <Box sx={{ ...styles.error }}>{errors}</Box>
                    </> : null}
                <hr />
            </div> : null
    );
}
