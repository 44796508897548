import React from 'react';
import { useTheme } from '@mui/material';
import { IconButton, Popover, Container } from '@mui/material';
import { Info } from '@mui/icons-material';
import parse from 'html-react-parser';
import { InfoMessages } from './';


export const InfoPopup = (props) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const styles = {
        typography: {
            padding: theme.spacing(2),
            border: '1px solid rgba(81,81,81,0.4)',
        },
        button: {
            color: theme.palette.primary.main,
        },
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const infoMessage = InfoMessages(props.info);

    return (
        <>
            <IconButton sx={{...styles.button}} aria-describedby={id} type="button" title="Information" onClick={handleClick}>
                <Info />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Container maxWidth="xs" sx={{...styles.typography}}>
                    {parse(infoMessage)}
                </Container>
            </Popover>
        </>
    );
}
