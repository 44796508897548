import { AppDarkTheme, AppLightTheme } from '../themes';
import { StorageKeys } from '../config';

export const RootContextReducer = (state, action) => {
    switch (action.type) {
        case StorageKeys.theme:
            const theme = action.payload === AppLightTheme.palette.type ? AppLightTheme.palette.type : AppDarkTheme.palette.type
            return { ...state, theme };
        case StorageKeys.clientIsLocal:
            const clientIsLocal = action.payload === true
            return { ...state, clientIsLocal };        
        default:
            return state;
    }
};