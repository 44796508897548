
import { Helpers } from '../config';

const useResponseParser = (response) => {
  var contentType = response.headers.get("content-type");
  if (contentType) {
    if (contentType.indexOf("application/json") > -1) {
      return response.json();
    } else if (contentType.indexOf("text/plain") > -1) {
      return response.text();
    }
  }
  return response;
};

const tryParseBody = (exception) => {

  console.log(exception);

  if(`${exception}`==='TypeError: Failed to fetch'){
    return Promise.resolve('API access error (TypeError: Failed to fetch)');
  }

  try {
    const promise = exception.json();
    return promise
      .then((exData) => {
        return Helpers.isNoneOrDefault(exData) ?
          Helpers.stringifyError(exception) :
          exData;
      }).catch((ex) => {
        return Helpers.stringifyError(exception);
      });
  } catch{
    return Helpers.stringifyError(exception);
  }
}

export const FetchHelper = {
  useResponseParser,
  tryParseBody,
};
