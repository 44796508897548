import React from 'react';
import { useTheme, Typography, Container, Box } from '@mui/material';

export default function ApduParsingResult(props) {
    const theme = useTheme();
    const styles = {
        container: {
            marginTop: '20px',
            background: theme.palette.background.paper,
            padding: 0,
        },
        result: {
            fontSize: '1.2rem'
        },
        warn: {
            color: theme.palette.warning.main,
        },
        error: {
            color: theme.palette.error.main,
        }
    };
    const data = props.data && (
        props.data.value ||
        props.data.error ||
        { message: props.data.statusText || 'Unknown error occurred' }
    );

    const messageClass = (data && data.status >= 400) ? styles.error : ((data.message && data.message.indexOf('IsValid:False') === -1) ? styles.result : styles.warn);

    return (
        data ? (
            <Container sx={{ ...styles.container }}>
                <Typography variable="h6" sx={{ textAlign: 'center' }}>
                    PARSING RESULTS
                </Typography>
                <Box sx={{ textAlign: 'left', whiteSpace: 'break-spaces' }}>
                    <pre style={{ ...messageClass, textWrap: 'wrap' }}>{data.message}</pre>
                </Box>
            </Container>
        ) : null
    );
}


