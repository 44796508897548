import React from 'react';
import { useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { Grid, Button, Box } from '@mui/material';
import parse from 'html-react-parser';
import { AppDarkTheme } from '../../themes';
import { InfoMessages } from './';



export const HomeInfoItem = (props) => {
    const theme = useTheme();

    const styles = {
        useFlex: {
            flexGrow: 1,
        },
        link: {
            color: theme.palette.type === AppDarkTheme.palette.type ? '#00bfff' : AppDarkTheme.palette.primary.main,
        }
    };
    const infoMessage = InfoMessages(props.infoType);

    return (
        <Box sx={{ ...styles.useFlex }}>
            <Grid container spacing={0}>
                <Grid item sm={3}>
                    <Box sx={{ ...styles.link }}>
                        <Button component={Link} to={props.linkTo} sx={{ ...styles.link }}>
                            {props.icon}&emsp;<span>{props.caption}</span>
                        </Button>
                    </Box>
                </Grid>
                <Grid item sm={9}>
                    {parse(infoMessage)}
                </Grid>
            </Grid>
        </Box>
    );
}