import React, { useState } from 'react';
import { Menu, MenuItem, IconButton, Box } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import ThemeSwitcher from './ThemeSwitcher';



export default function AppBarUserItems() {
    const [anchorEl, setAnchorEl] = useState(null);

    let isMenuOpen = Boolean(!!anchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const renderMenu = (
        <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleMenuClose}>
            <MenuItem>
                <ThemeSwitcher />Dark Theme
            </MenuItem>
        </Menu>
    );

    const renderMenuIcon = (
        <Box id="XXX" sx={{ justifyContent:'flex-end', width:'35px' }}>
            <IconButton edge="end" onClick={handleProfileMenuOpen} color="inherit" >
                <AccountCircle />
            </IconButton>
        </Box>);


    return (
        <Box>
            {renderMenuIcon}
            {renderMenu}
        </Box>
    );
}
