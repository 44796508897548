import { FetchHelper } from "./FetchHelper";

async function getData(urlOrPathAndQuery) {

  const requestOptions = {
    method: "GET",
  };

  const url = urlOrPathAndQuery;

  return await fetch(url, requestOptions)
    .then(response => {
      console.log(response);
      if (response.status !== 200) {
        return Promise.reject(response);
      }
      return FetchHelper.useResponseParser(response);
    }).then((result) => {
      if (!result) {
        return Promise.reject(
          new Error("DefaultGet: Returned not expected object type")
        );
      }
      return result;
    }).catch(ex => {
      return Promise.reject(FetchHelper.tryParseBody(ex));
    });
}

export const DefaultGet = {
  fetch: getData
};
