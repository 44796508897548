import React, { useState, useEffect } from 'react';
import { useTheme, Box } from '@mui/material';
import './loader.css';


let timer = null;

export default function LoadProgress(props) {

    const theme = useTheme();
    const [show, setShow] = useState(false);
    const styles = {
        back: {
            backgroundColor: theme.palette.text.primary,
        },
    };

    useEffect(() => {
        if (props.show) {
            timer = setTimeout(() => {
                setShow(true);
            }, 1000);
        } else {
            if (timer) {
                clearTimeout(timer);
            }
            setShow(false);
        }
        //return () => clearTimeout(timer);
    }, [props.show]);

    const locker = (
        <Box sx={{ ...styles.back, }} className='loader'>
            <Box
                title="reload"
                className="loaderImg loaderCircle"
                onClick={() => {
                    window.location.reload();
                    return false;
                }}
            ></Box>
        </Box>
    );

    return (show ? locker : null);
}