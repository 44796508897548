import { useTheme } from "@mui/material";

const useAppStyles = () => {

    const theme = useTheme();

    return {
        theme: theme,
        container: {
            maxWidth: '96vw',
            overflow: 'hidden',
            marginTop: theme.spacing(4),
        },
        inputStyle: {
            background: theme.palette.background.paper,
            border: `thin solid ${theme.palette.border.primary}`,
            borderRadius: theme.spacing(1),
            color: theme.palette.text.primary,
            '& *': { color: theme.palette.text.primary }
        },
        forContainingLegends: {
            '& .MuiFormLabel-root': {
                color: theme.palette.text.primary,
                //background: theme.palette.background.default
            },
            '& fieldset': {
                borderColor: theme.palette.border.default
            },
        },
    };
};

export default useAppStyles;