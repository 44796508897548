import React, { useState, useRef, Fragment } from 'react';
import { useTheme, Container, Typography, Box } from '@mui/material';
import { DefaultFileUpload } from '../../../../services/DefaultFileUpload';
import { Helpers } from '../../../../config';
import { FileParsingResults, FileUploadButton } from '../../../common';
import LoadProgress from '../../../../routing/LoadProgress';
import { InfoPopup, InfoTypes } from '../../../common';



export const TachoGen2FileParser = (props) => {

    const theme = useTheme();
    const styles = {
        container: {
            maxWidth: '96vw',
            overflow: 'hidden',
            textAlign: 'center',
            marginTop: theme.spacing(4),
        },
        center: {
            textAlign: 'center',
        },
        upper: {
            textTransform: 'uppercase',
        }
    };
    const inputEl = useRef(null);
    const [state, setState] = useState({ error: false, file: '', content: '' });
    const [fetching, setFetching] = useState(false);

    const onFileSelected = (event) => {
        setFetching(true);
        const selectedFile = inputEl.current.files[0];

        const promise = DefaultFileUpload.upload('/api/tachodatafileparser', selectedFile);

        promise.then((result) => {
            setState({ error: !!result.error, file: selectedFile.name, content: result.error ? result.error.message : result.value });
        }).catch((ex) => {
            var message = Helpers.stringifyError(ex);
            setState({ error: true, file: selectedFile.name, content: message });
        }).finally(() => {
            setFetching(false);
            inputEl.current.value = '';
        });
    }

    const saveToFile = () => {
        const mimeType = 'text/plain';
        const element = document.createElement("a");
        const file = new Blob([Helpers.formatXml(state.content)], { type: `${mimeType};charset=utf-8` });
        element.href = URL.createObjectURL(file);
        element.download = state.file + '.txt';
        document.body.appendChild(element);
        element.click();
    }

    return (
        <Fragment>
            <LoadProgress show={fetching}></LoadProgress>
            <Container maxWidth={false} sx={{ ...styles.container }}>
                <Typography variant="h5" sx={{ ...styles.center, ...styles.upper }}>
                    TACHOGRAPH DATA FILE PARSER <InfoPopup info={InfoTypes.tachoGen2FileParserInfo} />
                </Typography>
                <br />
                <Box sx={{ ...styles.center }}>
                    <FileUploadButton onFileSelected={onFileSelected} inputRef={inputEl} accept=".ddd,.v1b,.tgd" />
                </Box>
                <br />
                <FileParsingResults parentState={state} saveToFile={saveToFile} />
            </Container>
        </Fragment>
    );
}