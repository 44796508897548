import React, { useReducer } from 'react';
import { AppDarkTheme, AppLightTheme } from '../themes';
import { RootContext, RootContextReducer } from './';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Storage, StorageKeys } from '../config'


const initialState = {
    theme: (Storage.getItem(StorageKeys.theme) === AppLightTheme.palette.type ? AppLightTheme.palette.type : AppDarkTheme.palette.type),
    clientIp: Storage.getItem(StorageKeys.clientIp),
    clientIsLocal: Storage.getItem(StorageKeys.clientIsLocal) === 'true',
};


export const RootState = ({ children }) => {

    const [state, dispatch] = useReducer(RootContextReducer, initialState);
    const changeTheme = data => dispatch({ type: StorageKeys.theme, payload: data });
    const changeClientIsLocal = data => dispatch({ type: StorageKeys.clientIsLocal, payload: data });
    const muiTheme = createTheme(state.theme === AppLightTheme.palette.type ? AppLightTheme : AppDarkTheme);

    return (
        <RootContext.Provider value={{ state, changeTheme, changeClientIsLocal}}>
            <ThemeProvider theme={muiTheme}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </RootContext.Provider>
    );
}
