import React, { useState } from 'react';
import { useTheme } from '@mui/material';
import { TextField, Button } from '@mui/material';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import { DefaultPost } from '../../services/DefaultPost';
import LoadProgress from '../../routing/LoadProgress';
import { Helpers } from '../../config';


const sourceRegex = /^[0-9a-fA-F\s\-:]{4,}$/;

export default function FieldActionControl(props) {
    const theme = useTheme();
    const styles = {
        apduPaper: {
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            border:`thin solid ${theme.palette.primary.dark}`
        },
        input: {
            marginLeft: theme.spacing(1),
            paddingTop: '10px',
            flex: 1,
            height: '115px',
            whiteSpace: 'break-spaces',
        },
        parseButton: {
            height: '100px',
            width: '100px',
            borderRadius: '50%',
            textAlign: 'center',
            paddingBottom: '28px',
            marginRight: '3px',
        },
        clearButton: {
            position: 'absolute',
            zIndex: 1,
            right: '20px;',
            bottom: '8px',
            height: '36px',
            width: '66px',
            borderRadius: '50%',
            textAlign: 'center',
            fontSize: '0.7rem',
        }
    };
    const [source, setSource] = useState('');
    const [hasSource, setHasSource] = useState(false);
    const [sourceInvalid, setSourceInvalid] = useState(true);
    const [fetching, setFetching] = useState(false);

    const onSourceChange = (value) => {
        setSource(value);
        setHasSource(!!value);
        setSourceInvalid(!value || !value.match(sourceRegex))
    }

    function handleClick(ev) {
        setFetching(true);
        var requestData = { value: source };
        DefaultPost.fetch(props.url, requestData)
            .then(response => {
                props.handleChange(response);
            }).catch(ex => {
                var message = Helpers.stringifyError(ex);
                props.handleChange({ error: message });
            }).finally(() => {
                setFetching(false);
            });
    }

    function clear() {
        onSourceChange('');
        props.handleChange('');
    }

    return (
        <Paper sx={{
            ...styles.apduPaper,
            background: theme.palette.background.paper,  
            '& .TryParse:hover':{background: 'DodgerBlue', opacity:.8},      
            '& .Clear:hover':{background: 'Magenta', opacity:.8},    
        }}>
            <LoadProgress show={fetching}></LoadProgress>
            <InputBase
                control={TextField}
                sx={{ ...styles.input }}
                multiline
                rows={5}
                placeholder={props.placeholder}
                value={source}
                onChange={e => onSourceChange(e.target.value)}
            />
            <Button variant="outlined"
                disabled={sourceInvalid}
                className="TryParse"
                sx={{ ...styles.parseButton, background: 'DodgerBlue', cursor: 'pointer', color:theme.palette.primary.dark }}
                onClick={handleClick}>
                <span>Try parse</span>
            </Button>
            <Button
                variant="outlined"
                className="Clear"
                disabled={!hasSource}
                sx={{ ...styles.clearButton, background: 'Magenta', cursor: 'pointer', color:theme.palette.primary.dark }}
                onClick={clear}>
                <span>Clear</span>
            </Button>
        </Paper>
    );
}


