import React from 'react';
import { useTheme, Container, Typography, Divider, List, ListItem, Box } from '@mui/material';
import { CreditCard, ArtTrack, Toc, Settings, List as ListIcon, Storage, Code } from '@mui/icons-material'
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { HomeInfoItem, InfoTypes } from './common';



function Home(props) {
    const theme = useTheme();

    const accordionStyles = {
        root: {
            border: '1px solid rgba(0, 0, 0, .125)',
            boxShadow: 'none',
            '&:not(:last-child)': {
                borderBottom: 0,
            },
            '&:before': {
                display: 'none',
            },
            '&$expanded': {
                margin: 'auto',
            },
        },
        expanded: {},
    };

    const accordionSummaryStyles = {
        root: {
            backgroundColor: 'rgba(0, 0, 0, .03)',
            marginBottom: -1,
            minHeight: 56,
            '&$expanded': {
                minHeight: 56,
            },
        },
        content: {
            '&$expanded': {
                margin: '12px 0',
            },
        },
        expanded: {},
    };

    const accordionDetailsStyles = {
        root: {
            padding: '0',
        },
        '& a, a svg': {
            color: theme.palette.text.primary
        }
    };

    const dividerStyles = {
        background: theme.palette.border.primary,
        opacity: .4,
    };

    return (
        <Container maxWidth="xl" sx={{
            '& svg': { color: theme.palette.text.primary },
            '& .MuiPaper-root': {
                marginBottom: theme.spacing(1),
                boxShadow: 'none'
            },
        }}>
            <Box sx={{ textAlign: 'center', padding: theme.spacing(4) }}>
                <Typography variant='h4'>Please choose tool from list or use menu</Typography>
            </Box>
            <MuiAccordion sx={{ ...accordionStyles }}>
                <MuiAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{ ...accordionSummaryStyles }} >
                    <Typography variant="h6">TACHO TOOLS</Typography>
                </MuiAccordionSummary>
                <MuiAccordionDetails sx={{ ...accordionDetailsStyles }}>
                    <List dense={true} sx={{ width: '100%', }}>
                        <ListItem key="ApduParser">
                            <HomeInfoItem
                                linkTo="/tacho/apduParser"
                                infoType={InfoTypes.apduParserInfo}
                                icon={<CreditCard />}
                                caption="APDU Parser" />
                        </ListItem>
                        <Divider sx={{ ...dividerStyles }} />
                        <ListItem key="TachoGen2FileParser">
                            <HomeInfoItem
                                linkTo="/tacho/gen2"
                                infoType={InfoTypes.tachoGen2FileParserInfo}
                                icon={<Toc />}
                                caption="File Parser (Gen2)" />
                        </ListItem>
                    </List>
                </MuiAccordionDetails>
            </MuiAccordion>

            <MuiAccordion sx={{ ...accordionStyles }}>
                <MuiAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{ ...accordionSummaryStyles }}>
                    <Typography variant="h6">TAVL TOOLS</Typography>
                </MuiAccordionSummary>
                <MuiAccordionDetails sx={{ ...accordionDetailsStyles }}>
                    <List dense={true} sx={{ width: '100%', }} >
                        <ListItem key="DeviceRecordFileParser">
                            <HomeInfoItem
                                linkTo="/tavl/records/parser"
                                infoType={InfoTypes.recordParserInfo}
                                icon={<ArtTrack />}
                                caption="Record parser" />
                        </ListItem>
                        <Divider sx={{ ...dividerStyles }} />
                        <ListItem key="AvlPacketParser" >
                            <HomeInfoItem
                                linkTo="/tavl/packet/parser"
                                infoType={InfoTypes.avlPacketParserInfo}
                                icon={<ListIcon />}
                                caption="AVL packet parser" />
                        </ListItem>
                        <Divider sx={{ ...dividerStyles }} />
                        <ListItem key="RawDataParser">
                            <HomeInfoItem
                                linkTo="/tavl/rawdata/parser"
                                infoType={InfoTypes.rawDataFileParserInfo}
                                icon={<Storage />}
                                caption="RawData file parser" />
                        </ListItem>
                        <Divider sx={{ ...dividerStyles }} />
                        <ListItem key="DataSourceSqlGenerator">
                            <HomeInfoItem
                                linkTo="/tavl/dssqlgenerator"
                                infoType={InfoTypes.dsSqlGeneratorInfo}
                                icon={<Code />}
                                caption="SQL DS generator" />
                        </ListItem>
                    </List>
                </MuiAccordionDetails>
            </MuiAccordion>

            <MuiAccordion sx={{ ...accordionStyles }}>
                <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ ...accordionSummaryStyles }}>
                    <Typography variant="h6">FIRMWARE TOOLS</Typography>
                </MuiAccordionSummary>
                <MuiAccordionDetails sx={{ ...accordionDetailsStyles }}>
                    <List dense={true} sx={{ width: '100%', }}>
                        <ListItem key="FirmwareInfoParser" >
                            <HomeInfoItem
                                linkTo="/tavl/firmware/info"
                                infoType={InfoTypes.firmwareInfo}
                                icon={<Settings />}
                                caption="Firmware Info" />
                        </ListItem>
                    </List>
                </MuiAccordionDetails>
            </MuiAccordion>
        </Container>
    );

}

export default Home;