import React, { useState, useRef, useEffect, Fragment } from 'react';
import { Container, Typography, Grid, FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';
import { DefaultFileUpload } from '../../../services/DefaultFileUpload';
import { Helpers } from '../../../config';
import { FileParsingResults, FileUploadButton, InfoPopup, InfoTypes } from '../../common';
import LoadProgress from '../../../routing/LoadProgress';
import useAppStyles from '../../common/useAppStyles';



export const DeviceRecordFileParser = () => {
    const { theme, inputStyle, forContainingLegends } = useAppStyles();
    const inputEl = useRef(null);
    const [state, setState] = useState({ error: false, file: '', content: '' });
    const [codec, setSetCodec] = useState(1);
    const [parseAs, setParseAs] = useState(1);
    const saveLink = useRef();
    const [fetching, setFetching] = useState(false);

    useEffect(() => {
        if (saveLink.current) {
            saveLink.current.onclick = () => saveToFile.apply();
        }
    });

    const onFileSelected = (event) => {

        const selectedFile = inputEl.current.files[0];

        const url = `/api/${parseAs === 1 ? 'devicerecordfiledata' : 'devicerecordfileaskml'}?codec8E=${codec === 1}`;

        const promise = DefaultFileUpload.upload(url, selectedFile);
        setFetching(true);
        promise.then((result) => {
            setState({ error: false, file: selectedFile.name, content: result });
        }).catch((ex) => {
            var message = Helpers.stringifyError(ex);
            setState({ error: true, file: selectedFile.name, content: message });
        }).finally(() => {
            setFetching(false);
            inputEl.current.value = '';
        });
    }

    const handleCodecChange = (event) => {
        setSetCodec(event.target.value);
    }

    const handleParseAsChange = (event) => {
        setParseAs(event.target.value);
    }

    const saveToFile = () => {
        const mimeType = parseAs === 1 ? 'text/plain' : 'text/xml';
        const element = document.createElement("a");
        const file = new Blob([Helpers.formatXml(state.content)], { type: `${mimeType};charset=utf-8` });
        element.href = URL.createObjectURL(file);
        element.download = state.file + (parseAs === 1 ? '.txt' : '.kml');
        document.body.appendChild(element);
        element.click();
    }

    return (
        <Fragment>
            <LoadProgress show={fetching}></LoadProgress>
            <Container maxWidth={false} sx={{ marginTop: theme.spacing(4), }}>
                <Typography variant="h5" sx={{ textAlign: 'center' }}>
                    DEVICE RECORD DATA PARSER <InfoPopup info={InfoTypes.recordParserInfo} />
                </Typography>
                <br />
                <Container maxWidth="xs" sx={{ ...forContainingLegends }} >
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" sx={{
                                width: '100%',
                                '& *': {
                                    color: theme.palette.text.primary,
                                }
                            }}>
                                <InputLabel id="codec-select-outlined-label">Source file encoder</InputLabel>
                                <Select
                                    sx={{ ...inputStyle }}
                                    labelId="codec-select-outlined-label"
                                    id="codec-select-outlined"
                                    value={codec}
                                    onChange={handleCodecChange}
                                    label="Codec" >
                                    <MenuItem value={1}>Codec 8E</MenuItem>
                                    <MenuItem value={2}>Codec 8 (outdated)</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" sx={{
                                width: '100%',
                                '& *': {
                                    color: theme.palette.text.primary,
                                }
                            }}>
                                <InputLabel id="codec-parseAs-outlined-label">Parsing result</InputLabel>
                                <Select
                                    sx={{ ...inputStyle }}
                                    labelId="codec-parseAs-outlined-label"
                                    id="codec-parseAs-outlined"
                                    value={parseAs}
                                    onChange={handleParseAsChange}
                                    label="Parse as" >
                                    <MenuItem value={1}>Text content</MenuItem>
                                    <MenuItem value={2}>Google Earth KML content</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Container>
                <br />
                <Box sx={{ textAlign: 'center' }}>
                    <FileUploadButton onFileSelected={onFileSelected} inputRef={inputEl} accept=".bin" />
                </Box>
                <br />
                <FileParsingResults parentState={state} saveToFile={saveToFile} />
            </Container>
        </Fragment>
    );
}