import React, { useState, useContext } from 'react';
import Switch from '@mui/material/Switch';
import { AppDarkTheme, AppLightTheme } from '../../themes';
import { RootContext } from '../../contexts';
import { Storage, StorageKeys } from '../../config';

const styles =  {
    themeSwitch: {
        marginLeft: '-5px',
    },
};

export default function ThemeSwitcher() {
    const currentTheme = Storage.getItem(StorageKeys.theme) === AppLightTheme.palette.type ? AppLightTheme.palette.type : AppDarkTheme.palette.type;
    const [theme, setTheme] = useState({
        theme: currentTheme,
        checked: currentTheme === AppDarkTheme.palette.type
    });

    const { changeTheme } = useContext(RootContext);

    const handleChange = (event) => {
        var checked = event.target.checked;
        const theme = checked ? AppDarkTheme.palette.type : AppLightTheme.palette.type;
        Storage.setItem(StorageKeys.theme, theme);
        setTheme({ theme, checked });
        changeTheme(theme);
    };

    return (

        <Switch
            checked={theme.checked}
            onChange={(event) => handleChange(event)}
            color="primary"
            name="themeSwitch"
            sx={{...styles.themeSwitch}}
            inputProps={{ 'aria-label': 'primary checkbox' }}
        />
    );
}
