import React, { useState, useRef, useEffect } from 'react';
import { Box, Container, Typography, Button, TextField } from '@mui/material';
import { Save } from '@mui/icons-material';
import { DefaultFileUpload } from '../../../services/DefaultFileUpload';
import { Helpers } from '../../../config';
import { FileUploadButton, InfoPopup, InfoTypes } from '../../common';
import LoadProgress from '../../../routing/LoadProgress';
import { RawData } from './RawData';
import useAppStyles from '../../common/useAppStyles';


export const RawDataParser = () => {

    const { theme, inputStyle, container } = useAppStyles();

    const styles = {
        controls: {
            display: 'flex',
            justifyContent: 'space-evenly',
            '& *': { color: theme.palette.text.primary }
        },
        caption: {
            textTransform: 'uppercase',
            textAlign: 'center',
        },
        fileBrowseButton: {
            paddingTop: '12px',
        },
        fileInfo: {
            display: 'flex',
            justifyContent: 'space-between',
            paddingBottom: '10px',
        },
    };

    const inputEl = useRef(null);
    const [state, setState] = useState({ error: false, file: '', content: '' });
    const saveLink = useRef();
    const [fetching, setFetching] = useState(false);
    const [vehicleId, setVehicleId] = useState('');

    useEffect(() => {
        if (saveLink.current) {
            saveLink.current.onclick = () => saveToFile.apply();
        }
    });

    const onFileSelected = (event) => {

        const selectedFile = inputEl.current.files[0];

        const url = `/api/tavlrawdatafileparser?vehicleId=${vehicleId}`;

        const promise = DefaultFileUpload.upload(url, selectedFile);
        setFetching(true);
        promise.then((result) => {
            result.vehicles = result.vehicles?.map(v => {
                v.messages = v.messages?.map(m => m.replaceAll("\r\n", "<br/>").replaceAll("\t", "<span>&emsp;</span>"));
                return v;
            });
            setState({
                error: result.errors && result.errors.length,
                file: selectedFile.name,
                content: result
            });
        }).catch((ex) => {
            var message = Helpers.stringifyError(ex);
            setState({ error: true, file: selectedFile.name, content: { errors: [message] } });
        }).finally(() => {
            setFetching(false);
            if (inputEl.current && inputEl.current.value)
                inputEl.current.value = '';
        });
    }


    const saveToFile = () => {
        const mimeType = 'application/json';
        const element = document.createElement("a");
        const data = state.content;
        data.vehicles = data.vehicles?.map(v => {
            v.messages = v.messages?.map(m => m.replaceAll("<br/>", '').replaceAll("<span>&emsp;</span>", ''));
            return v;
        });
        const file = new Blob([JSON.stringify(data, null, 5)], { type: `${mimeType};charset=utf-8`, endings: 'native' });
        element.href = URL.createObjectURL(file);
        element.download = state.file + '.json';
        document.body.appendChild(element);
        element.click();
    }

    return (
        <>
            <LoadProgress show={fetching}></LoadProgress>
            <Container maxWidth={false} sx={{ ...container }}>
                <Typography variant="h5" sx={{ ...styles.caption }}>
                    TAVL DATA SERVER RAWDATA FILE PARSER <InfoPopup info={InfoTypes.rawDataFileParserInfo} />
                </Typography>
                <br />
                <Box sx={{ ...styles.controls }}>
                    <TextField
                        id="filter"
                        sx={{ ...inputStyle }}
                        label="Vehicle ID"
                        title="Vehicle/Object ID (optional filter for request only)"
                        type="number"
                        InputLabelProps={{ shrink: true, }}
                        value={vehicleId}
                        onChange={e => setVehicleId(e.target.value)}
                        variant="outlined"
                    />
                    <Box sx={{ ...styles.fileBrowseButton }}>
                        <FileUploadButton onFileSelected={onFileSelected} inputRef={inputEl} accept=".raw,.completed,.errors,.7z,.zip,.rar" />
                    </Box></Box>
                <br />
                {!!state.file ?
                    <Box sx={{ ...styles.fileInfo }}>
                        <Typography variant="subtitle1">File: {state.file}</Typography>
                        <Button variant="outlined" color="primary" component="span" title="Save text to file" onClick={saveToFile}>
                            <Save />
                            <span>Save to file</span>
                        </Button>
                    </Box> : null}
                <RawData data={state.content} vehicleId={vehicleId} />
            </Container>
        </>
    );
}