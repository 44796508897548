const AppColors = {
  background: {
    default: '#fff',
    paper: '#fafafa'
  },
  light: '#20C0D7',
  main: '#0054a6',
  dark: '#1e3d71',
}

export const AppLightTheme = {
  overrides: {
    MuiPaper: {
      root: {
        //backgroundColor: '#efefef',
        //border: `solid thin ${AppLightColors.main}`,
      },
    },
  },
  palette: {
    type: 'light',
    background: {
      paper: AppColors.background.paper,
      default: AppColors.background.default,
    },
    text: {
      primary: "#000",
      contrast: "#fff",
    },
    border: {
      default: "RGBA(0,0,0,0.2)",
      primary: "RGBA(0,0,0,0.4)",
    },
    primary: {
      light: AppColors.light,
      main: AppColors.main,
      dark: AppColors.dark,
    },
    // secondary: {
    //   light: '#ff7961',
    //   main: '#f44336',
    //   dark: '#ba000d',
    //   contrastText: '#000',
    //   error: '#ff0000',
    // }
  }
};

