import { StorageKeys } from ".";

const Storage = {
    setItem: function (key, value) {
        localStorage.setItem(key, value);
        return value;
    },

    getItem: function (key) {
        return localStorage.getItem(key);
    },

    removeItem: function (key) {
        const current = localStorage.getItem(key);
        localStorage.removeItem(key);
        return current;
    },

    clear: function () {
        console.log("Storage.clear()-> clearing...");
        localStorage.removeItem(StorageKeys.lastOperationDateTime);
    },
};

export { Storage };
