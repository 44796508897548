

import { useContext } from 'react';
import { RootContext } from '../../contexts';
import { AppDarkTheme } from '../../themes';


export const InfoTypes = {
    apduParserInfo: 'apduParserInfo',
    tachoGen2FileParserInfo: 'tachoGen2FileParserInfo',
    recordParserInfo: 'recordParserInfo',
    avlPacketParserInfo: 'avlPacketParserInfo',
    rawDataFileParserInfo: 'rawDataFileParserInfo',
    firmwareInfo: 'firmwareInfo',
    dsSqlGeneratorInfo:'dsSqlGeneratorInfo',
};

export const InfoMessages = (infoType) => {
    const { state } = useContext(RootContext);
    const anchorStyle = state.theme === AppDarkTheme.palette.type ? "style='color:#00bfff'" : '';

    switch (infoType) {
        case InfoTypes.apduParserInfo:
            return "<div>Tool parses APDU packet hex string (used in Tachograph-CompanyCard-CardReader communication).</div>" +
                "<div>Test sample strings:</div>" +
                "<div>9000</div>" +
                "<div>0C-01-10-00-00-00-03-12-90-00-01</div>" +
                "<div>0C 01 10 00 00 00 0C 12 04 00 07 E0 17 07 15 14 AD 90 00 01</div>" +
                "<div>67:00</div>";
        case InfoTypes.tachoGen2FileParserInfo:
            return "<div>Tool parses data file data of second generation Tachograph only.</div>" +
                "<div>[Overview...TechnicalData] data testing file could be downloaded using link: " +
                `<a ${anchorStyle} href="/files/M_20190730_0641_ABC123_AABBCCDDEE1234567.DDD" download>` +
                "M_20190730_0641_ABC123_AABBCCDDEE1234567.DDD</a></div>";
        case InfoTypes.recordParserInfo:
            return "<div>Tool parses message information from encoded Teltonika device record *.bin file (could be extracted using Teltonika Configurator software).</div>" +
                "<div>Parser supports device record data encoded using Codec8 or Codec8E codecs.</div>" +
                "<div>Data could be parsed to simple text or Google Earth KML file format.</div>" +
                "<div>Google Earth KML file could be loaded using " +
                `<a ${anchorStyle}  href='https://www.google.com/earth/versions/' ` +
                "target='_blank'  rel='noopener noreferrer'>Google Earth application.</a></div>" +
                "<div>Sample file could be downloaded using link: " +
                `<a ${anchorStyle} href="/files/842_vaziavimas.bin" download >842_vaziavimas.bin</a></div>`;
        case InfoTypes.avlPacketParserInfo:
            return "<div>Tool parses hexadecimal byte string received by server from device.</div>" +
                "<div> Useful for parsing Teltonika dataServer logs.</div>" +
                "<div>TCP packet byte string sample for testing:</div>" +
                "<div>00 00 00 00 00 00 00 47 08 01 00 00 01 74 72 48 " +
                "64 48 00 04 8D 58 52 1A F1 0C BA 01 33 01 54 0D " +
                "00 00 00 0D 07 EF 00 F0 00 01 00 B4 00 51 00 59 " +
                "00 98 00 03 42 30 49 18 00 00 43 0F F1 03 64 00 " +
                "00 2D CF 66 00 00 00 00 69 00 00 00 00 00 01 00 " +
                "00 32 6F</div>" +
                "<div>Parser accepts byte string delimiters [\\s-:] </div>";
        case InfoTypes.rawDataFileParserInfo:
            return "<div>Tool parses Teltonika DataServer RawData files (*.raw, *.raw.completed, *.raw.errors) or an archive file made up of them and having size up to 200MB.</div>" +
                "<div>Use Vehicle ID filter to avoid browser crashes on rendering large parsed data.</div>" +
                "<div>Sample file could be downloaded using link: " +
                `<a ${anchorStyle} href="/files/DataServer-20200909-100256-1042408.raw" download >DataServer-20200909-100256-1042408.raw</a></div>`;
        case InfoTypes.firmwareInfo:
            return "<div>Tool extracts version information from Teltonika device firmware file.</div>" +
                "<div>Sample file could be downloaded using link: " +
                `<a ${anchorStyle} href="/files/FMB.Ver.03.25.15.Rev.01_inc_ImeiList.e.xim" download>` +
                "FMB.Ver.03.25.15.Rev.01_inc_ImeiList.e.xim</a></div>";
     case InfoTypes.dsSqlGeneratorInfo:
            return "<div>Tool checks and parses uploaded excel file containing AVL ID information.</div>" +
                "<div>SQL could be saved ignoring file parsing errors.</div>" +
                "<div>Sample file could be downloaded using link: " +
                `<a ${anchorStyle} href="/files/FMB_AVL_IDS.xlsx" download>` +
                "FMB_AVL_IDS.xlsx</a></div>" +
                "<div>Original AVL ID file source can be found in the " +
                `<a ${anchorStyle} href="https://gps-gitlab.teltonika.lt/fleet/fmb/fmb-fmb6-eq-avl-ids" target="_blank" rel="noopener noreferrer">` +
                "gps-gitlab.teltonika.lt</a></div>"
                ;        
        default:
            throw new Error('InfoType for InfoMessage NOT DEFINED!')
    }
}