import React, { useState, useRef } from 'react';
import { Container, Typography, Box, useTheme } from '@mui/material';
import { DefaultFileUpload } from '../../../../services/DefaultFileUpload';
import { Helpers } from '../../../../config';
import { FileParsingResults, FileUploadButton, InfoPopup, InfoTypes } from '../../../common';
import LoadProgress from '../../../../routing/LoadProgress';



export const FirmwareInfoParser = (props) => {

    const theme = useTheme();
    const styles = {
        center: {
            textAlign: 'center',
        },
        upper: {
            textTransform: 'uppercase',
        },
    };
    const inputEl = useRef(null);
    const [state, setState] = useState({ error: false, file: '', content: '' });
    const [fetching, setFetching] = useState(false);

    const onFileSelected = (event) => {
        setFetching(true);
        const selectedFile = inputEl.current.files[0];

        const promise = DefaultFileUpload.upload('/api/firmwareinfoparser', selectedFile);

        promise.then((result) => {
            setState({ error: false, file: selectedFile.name, content: result });
        }).catch((ex) => {
            var message = Helpers.stringifyError(ex);
            setState({ error: true, file: selectedFile.name, content: message });
        }).finally(() => {
            setFetching(false);
            inputEl.current.value = '';
        });
    }

    const saveToFile = () => {
        const mimeType = 'application/json';
        const element = document.createElement("a");
        const file = new Blob([Helpers.formatXml(state.content)], { type: `${mimeType};charset=utf-8` });
        element.href = URL.createObjectURL(file);
        element.download = state.file + '.json';
        document.body.appendChild(element);
        element.click();
    }

    return (
        <>
            <LoadProgress show={fetching}></LoadProgress>
            <Container maxWidth="sm" sx={{ marginTop: theme.spacing(4), }}>
                <Typography variant="h5" sx={{ ...styles.center, ...styles.upper }}>
                    TELTONIKA DEVICE FIRMWARE INFO <InfoPopup info={InfoTypes.firmwareInfo} />
                </Typography>
                <br />
                <Box sx={{ ...styles.center }}>
                    <FileUploadButton onFileSelected={onFileSelected} inputRef={inputEl} accept=".e.cfw,.e.bin,.e.xim,.zip,.xim,.cfw,.bin" />
                </Box>
                <br />
                <FileParsingResults parentState={state} saveToFile={saveToFile} />
            </Container>
        </>
    );
}