import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import Container from '@mui/material/Container';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}



export default function SignIn() {
  const theme = useTheme();
  const styles = {
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
      width: '80px',
      height: '80px',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  };
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box sx={{ ...styles.paper }}>
        <Avatar sx={{ ...styles.avatar }}>
          <img alt="UAB Teltonika" src="favicon.ico" />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form sx={{ ...styles.form }} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            sx={{ width: '100%', }}
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            sx={{ width: '100%', }}
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ ...styles.submit, width: '100%', }}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" color="textPrimary">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" color="textPrimary">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}