import { FetchHelper } from "./FetchHelper";

async function postData(pathAndQuery, data = null) {

  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data || ""),
    headers: { 'content-type': 'application/json' }
  };

  const url = pathAndQuery;

  return await fetch(url, requestOptions)
    .then(response => {
      console.log(response);
      // if (response.status !== 200) {
      //   return Promise.reject(response);
      // }

      return FetchHelper.useResponseParser(response);
    })
    .then((result) => {
      console.log(result);
      return Promise.resolve(result);
    })
    .catch(ex => {
      return Promise.reject(FetchHelper.tryParseBody(ex));
    });
}

export const DefaultPost = {
  fetch: postData
};
