import React, { useState } from 'react';
import { useTheme } from '@mui/material';
import { Grid, Container, Typography } from '@mui/material';
import ApduParsingResult from './ApduParsingResult';
import { InfoPopup, InfoTypes } from '../../common';
import FieldActionControl from '../../common/FieldActionControl';



export default function ApduParser(props) {
    const theme = useTheme();
    const styles = {
        center: {
            textAlign: 'center',
        },
        container: {
            maxWidth: '96vw',
            overflow: 'hidden',
            textAlign: 'center',
            marginTop: theme.spacing(4),
        },
    };
    const [parsedData, setParsedData] = useState('');

    const handleSourceChange = (value) => {
        setParsedData(value || '');
    }

    return (
        <Container maxWidth="sm" sx={{ ...styles.container }}>

            <Grid container spacing={2} >
                <Grid item xs>
                    <Typography variant="h5" sx={{ ...styles.center }}>
                        APDU PARSER<InfoPopup info={InfoTypes.apduParserInfo} />
                    </Typography>
                    <br />
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs >
                    <FieldActionControl
                        url={"/api/apduparser"}
                        placeholder={"APDU..."}
                        handleChange={handleSourceChange} />
                </Grid>
            </Grid>

            <ApduParsingResult data={parsedData} />
        </Container >
    );
}


