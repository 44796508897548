import React from 'react';
import parse from 'html-react-parser';
import { useTheme, Box } from '@mui/material';



const delimiter = '-----------------------';
export const RawData = (props) => {

    const theme = useTheme();
    const styles = {
        error: {
            color: theme.palette.error.main,
        },
    };
    const errors = props.data?.errors?.map((x, i) => <div key={"error_" + i}>{x}</div>) || <div>{delimiter}</div>;

    const vehicles = props.data?.vehicles?.map((v, vi) => {
        return (
            <div key={"vehicle_" + vi}>
                <div key={"vehicle_id_" + v.vehicleId}>
                    VEHICLE ID: {v.vehicleId}, file: {v.fileName}
                </div>
                <br />
                {v.messages?.map((m, i) => {
                    return (<div key={"message_" + i + '_' + v.vehicleId}>{parse(m)}<div>&nbsp;</div></div>)
                })}
                <hr />
            </div>
        );
    });

    return (
        (props.data?.vehicles?.length || props.data?.errors?.length) ?
            <Box>
                <hr />
                <h5>FILTER: {props.vehicleId && (props.vehicleId + " (Vehicle ID)")}</h5>
                <h5>ERRORS:</h5>
                <Box sx={{ ...styles.error }}>{errors}</Box>
                <h5>VEHICLE/FILE COUNT: {props.data?.vehicles?.length || 0}</h5>
                <h5>TOTAL MESSAGE COUNT: {props.data?.totalMessages || 0}</h5>
                <hr />
                {vehicles}
            </Box> : null
    );
}
