import React, { useState } from 'react';
import { Grid, Container, Typography, useTheme } from '@mui/material';
import AvlParsingResult from './AvlParsingResult';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { InfoPopup, InfoTypes } from '../../common';
import FieldActionControl from '../../common/FieldActionControl';


export function AvlParser(props) {
    const [parsedData, setParsedData] = useState('');
    const [tcp, setTcp] = useState(true);
    const theme = useTheme();
    const handleSourceChange = (value) => {
        setParsedData(value || '');
    }

    return (
        <Container maxWidth="sm" sx={{
            marginTop: theme.spacing(4),
            padding: `${theme.spacing(1)} !important`,
            '& .MuiContainer-root': { padding: '0 !important' }
        }}>
            <Grid container spacing={2} >
                <Grid item xs>
                    <Typography variant="h5" sx={{ textAlign: 'center' }}>
                        AVL DATA PACKET PARSER <InfoPopup info={InfoTypes.avlPacketParserInfo} />
                    </Typography>
                    <br />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs>
                    <FormControlLabel control={<Switch color="primary" checked={tcp} onChange={() => setTcp(!tcp)} />} label={tcp ? 'TCP' : 'UDP'} />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs >
                    <FieldActionControl
                        url={`/api/avlparser?tcp=${tcp}`}
                        placeholder={'DATA...'}
                        handleChange={handleSourceChange} />
                </Grid>
            </Grid>
            <AvlParsingResult tcp={tcp} data={parsedData} />
        </Container >
    );
};