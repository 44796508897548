import React, { Fragment } from 'react';
import { Typography, Button, Box, useTheme } from '@mui/material';
import { Save } from '@mui/icons-material';
import parse from 'html-react-parser';


export const FileParsingResults = (props) => {

    const theme = useTheme();
    const styles = {
        error: {
            color: theme.palette.error.main,
            fontSize: '1.5em',
        },
        container: {
            background: theme.palette.background.paper,
            display: 'block',
            position: 'absolute',
            left: theme.spacing(2),
            right: theme.spacing(2),
            padding: theme.spacing(1), textAlign: 'left',
        },
        content: {
            wordBreak: 'break-word',
            textWrap: 'wrap',
        },
        fileInfo: {
            display: 'flex',
            justifyContent: 'space-between',
            paddingBottom: '10px',
        },
    };

    return (
        <Fragment>
            {!!props.parentState.file ? <Box sx={{ ...styles.fileInfo }}>
                <Typography variant="subtitle1">File: {props.parentState.file}</Typography>
                <Button variant="outlined" color="primary" component="span" title="Save text to file" onClick={props.saveToFile}>
                    <Save />
                    <span>Save to file</span>
                </Button>
            </Box> : null}
            {props.parentState.content ?
                <Box sx={{ ...styles.container }}>
                    <pre style={{ ...styles.content }}>
                        {parse(props.parentState.content)}
                    </pre>
                </Box>
                : null
            }
        </Fragment>
    );
}