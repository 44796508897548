import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme, Typography, Divider, List, ListItem, ListItemText, ListItemIcon, Box } from '@mui/material';
import { Home, CreditCard, ArtTrack, Toc, Settings, List as ListIcon, Storage, Code } from '@mui/icons-material'
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export const LeftMenuItems = (props) => {
    const theme = useTheme();

    const accordionStyles = {
        root: {
            border: '1px solid rgba(0, 0, 0, .125)',
            '&:not(:last-child)': {
                borderBottom: 0,
            },
            '&:before': {
                display: 'none',
            },
            '&$expanded': {
                margin: 'auto',
            },
            '& .MuiPaper-root': {
                boxShadow: 'none !important',
            }
        },
        expanded: {},
        '& *': {
            color: theme.palette.text.primary,
        }
    };

    const accordionSummaryStyles = {
        root: {
            backgroundColor: 'rgba(0, 0, 0, .03)',
            borderBottom: '1px solid rgba(0, 0, 0, .125)',
            marginBottom: -1,
            minHeight: 56,
            '& $expanded': {
                minHeight: 56,
            },
        },
        content: {
            '&$expanded': {
                margin: '12px 0',
            },
        },
        expanded: {
            '& *': { background: 'red' }
        },
    };

    const accordionDetailsStyles = {
        root: {
            padding: '0'
        },
    };

    const dividerStyles = {
        background: theme.palette.border.primary,
        opacity: .4,
    };

    return (
        <Box sx={{
            '& a:hover': { textDecoration: 'underline' },
            '& *': { boxShadow: 'none !important', color: theme.palette.text.primary, },
        }}>
            <List sx={{ ...accordionDetailsStyles }}>
                <ListItem key="Home" component={Link} to={`/home`} onClick={props.handleDrawerClose} >
                    <ListItemIcon>
                        <Home />
                    </ListItemIcon>
                    <ListItemText primary="Home" />
                </ListItem>
            </List>
            <Divider sx={{ ...dividerStyles }} />
            <MuiAccordion defaultExpanded sx={{ ...accordionStyles }}>
                <MuiAccordionSummary
                    sx={{ ...accordionSummaryStyles }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography >Tacho</Typography>

                </MuiAccordionSummary>
                <Divider sx={{ ...dividerStyles }} />
                <MuiAccordionDetails sx={{ ...accordionDetailsStyles }}>
                    <List>
                        <ListItem key="ApduParser" component={Link} to={`/tacho/apduParser`} onClick={props.handleDrawerClose} >
                            <ListItemIcon>
                                <CreditCard />
                            </ListItemIcon>
                            <ListItemText primary="APDU Parser" />
                        </ListItem>
                        <ListItem key="TachoGen2FileParser" component={Link} to={`/tacho/gen2`} onClick={props.handleDrawerClose} >
                            <ListItemIcon>
                                <Toc />
                            </ListItemIcon>
                            <ListItemText primary="File Parser (Gen2)" />
                        </ListItem>
                    </List>
                </MuiAccordionDetails>
            </MuiAccordion>
            <Divider sx={{ ...dividerStyles }} />
            <MuiAccordion defaultExpanded sx={{ ...accordionStyles }}>
                <MuiAccordionSummary
                    sx={{ ...accordionSummaryStyles }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography >Tavl</Typography>
                </MuiAccordionSummary>
                <Divider sx={{ ...dividerStyles }} />
                <MuiAccordionDetails sx={{ ...accordionDetailsStyles }}>
                    <List>
                        <ListItem key="DeviceRecordFileParser" component={Link} to={`/tavl/records/parser`} onClick={props.handleDrawerClose} >
                            <ListItemIcon>
                                <ArtTrack />
                            </ListItemIcon>
                            <ListItemText primary="Record parser" />
                        </ListItem>
                        <ListItem key="AvlPacketParser" component={Link} to={`/tavl/packet/parser`} onClick={props.handleDrawerClose} >
                            <ListItemIcon>
                                <ListIcon />
                            </ListItemIcon>
                            <ListItemText primary="AVL packet parser" />
                        </ListItem>
                        <ListItem key="RawDataParser" component={Link} to={`/tavl/rawdata/parser`} onClick={props.handleDrawerClose} >
                            <ListItemIcon>
                                <Storage />
                            </ListItemIcon>
                            <ListItemText primary="RawData file parser" />
                        </ListItem>
                        <ListItem key="DataSourceSqlGenerator" component={Link} to={`/tavl/dssqlgenerator`} onClick={props.handleDrawerClose} >
                            <ListItemIcon>
                                <Code />
                            </ListItemIcon>
                            <ListItemText primary="SQL DS generator" />
                        </ListItem>
                    </List>
                </MuiAccordionDetails>
            </MuiAccordion>
            <Divider sx={{ ...dividerStyles }} />
            <MuiAccordion defaultExpanded sx={{ ...accordionStyles }}>
                <MuiAccordionSummary
                    sx={{ ...accordionSummaryStyles }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                    <Typography >Firmware</Typography>
                </MuiAccordionSummary>
                <Divider sx={{ ...dividerStyles }} />
                <MuiAccordionDetails sx={{ ...accordionDetailsStyles }}>
                    <List>
                        <ListItem key="FirmwareInfoParser" component={Link} to={`/tavl/firmware/info`} onClick={props.handleDrawerClose} >
                            <ListItemIcon>
                                <Settings />
                            </ListItemIcon>
                            <ListItemText primary="Firmware Info" />
                        </ListItem>
                    </List>
                </MuiAccordionDetails>
            </MuiAccordion>
            <Divider sx={{ ...dividerStyles }} />
        </Box>
    );
} 