import React from 'react';
import { Button, FormLabel } from '@mui/material';
import { Publish } from '@mui/icons-material';

export const FileUploadButton = (props) => {

    return (
        <form id="fileForm"
            method="post"
            encType="multipart/form-data"
            action="/">
            <input
                ref={props.inputRef}
                accept={props.accept}
                style={{ display: 'none' }}
                id="contained-button-file"
                type="file"
                onChange={props.onFileSelected}
            />
            <FormLabel htmlFor="contained-button-file" >
                <Button variant="contained"                    
                    component="span"
                    title="Browse file to upload">
                    <Publish />
                    <span>Upload</span>
                </Button>
            </FormLabel>
        </form>
    );
}
